import React, { Component } from "react";
import Navigation from "./components/navigation";
import Header from "./components/header";
import Features from "./components/features";
import About from "./components/about";
import Services from "./components/services";
import Gallery from "./components/gallery";
import Testimonials from "./components/testimonials";
import Team from "./components/Team";
// import Contact from './components/contact';
import Con from "./components/contact2";
import JsonData from "./data/data.json";

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      landingPageData: {},
      navigatorField: "commerce",
    };
  }
  // state = {
  //   landingPageData: {},
  //   navigatorField :'commerce'
  // }
  getlandingPageData() {
    this.setState({ landingPageData: JsonData });
  }
  navigatorFieldSetting(val) {
    console.log("bbbbb", val);
    this.setState({ navigatorField: val });
  }

  componentDidMount() {
    this.getlandingPageData();
    // <script type="text/javascript">
    var ifrm = document.createElement("iframe");
    ifrm.setAttribute(
      "src",
      "https://www.careerguide.com/assessment/integration/engineering-branch-assessment.aspx?hide_head=1&utm_source=partner_assessment&utm_campaign=school2campus&utm_medium=school2campus"
    );
    ifrm.style.width = "100%";
    ifrm.style.height = "600px";
    ifrm.style.border = "0";
    document.getElementById("engineering-test").appendChild(ifrm);

    var ifrm = document.createElement("iframe");
    ifrm.setAttribute(
      "src",
      "https://www.careerguide.com/assessment/integration/commerce-assessment.aspx?hide_head=1&utm_source=partner_assessment&utm_campaign=school2campus&utm_medium=school2campus"
    );
    ifrm.style.width = "100%";
    ifrm.style.height = "600px";
    ifrm.style.border = "0";
    document.getElementById("commerce-test").appendChild(ifrm);

    var ifrm = document.createElement("iframe");
    ifrm.setAttribute(
      "src",
      "https://www.careerguide.com/assessment/integration/ideal-career-test.aspx?hide_head=1&utm_source=partner_assessment&utm_campaign=megamindonline&utm_medium=megamindonline"
    );
    ifrm.style.width = "100%";
    ifrm.style.height = "600px";
    ifrm.style.border = "0";
    document.getElementById("ideal-test").appendChild(ifrm);

    var ifrm = document.createElement("iframe");
    ifrm.setAttribute(
      "src",
      "https://www.careerguide.com/assessment/integration/humanities-assessment.aspx?hide_head=1&utm_source=partner_assessment&utm_campaign=school2campus&utm_medium=school2campus"
    );
    ifrm.style.width = "100%";
    ifrm.style.height = "600px";
    ifrm.style.border = "0";
    document.getElementById("humanities-test").appendChild(ifrm);


    var ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", "https://www.careerguide.com/assessment/integration/stream-suggestor.aspx?hide_head=1&utm_source=partner_assessment&utm_campaign=school2campus&utm_medium=school2campus");
    ifrm.style.width = "100%";
    ifrm.style.height = "600px";
    ifrm.style.border = "0";
    document.getElementById("stream-selector-test").appendChild(ifrm);

    var ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", "https://www.careerguide.com/assessment/integration/ideal-vocational-career-test.aspx?hide_head=1&utm_source=partner_assessment&utm_campaign=school2campus&utm_medium=school2campus");
    ifrm.style.width = "100%";
    ifrm.style.height = "600px";
    ifrm.style.border = "0";
    document.getElementById("skill-based-test").appendChild(ifrm);

    var ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", "https://www.careerguide.com/assessment/integration/teacher-career-assessment.aspx?hide_head=1&utm_source=partner_assessment&utm_campaign=school2campus&utm_medium=school2campus");
    ifrm.style.width = "100%";
    ifrm.style.height = "600px";
    ifrm.style.border = "0";
    document.getElementById("educator-professional-test").appendChild(ifrm);

    var ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", "https://www.careerguide.com/assessment/integration/college-career-assessment.aspx?hide_head=1&utm_source=partner_assessment&utm_campaign=school2campus&utm_medium=school2campus");
    ifrm.style.width = "100%";
    ifrm.style.height = "600px";
    ifrm.style.border = "0";
    document.getElementById("professional-skill-test").appendChild(ifrm);

    
  }

  render() {
    return (
      <div>
        {/* <Navigation onSelectNavigator={this.navigatorFieldSetting.bind(this)}  /> */}
        <Navigation />
        <Header data={this.state.landingPageData.Header} />
        <Features data={this.state.landingPageData.Features} />
        <About data={this.state.landingPageData.About} />
        <Services data={this.state.landingPageData.Services} />
        <Gallery />
        <Testimonials />
        {/* <div id={this.state.navigatorField} style={{ width: "100%", "text-align": "center" }}></div> */}
        <div
          id="commerce-test"
          style={{ width: "100%", "text-align": "center" }}
        ></div>
        <div
          id="engineering-test"
          style={{ width: "100%", "text-align": "center" }}
        ></div>
        <div
          id="ideal-test"
          style={{ width: "100%", "text-align": "center" }}
        ></div>
        <div
          id="humanities-test"
          style={{ width: "100%", "text-align": "center" }}
        ></div>
        <div
          id="stream-selector-test"
          style={{ width: "100%", "text-align": "center" }}
        ></div>
        <div
          id="skill-based-test"
          style={{ width: "100%", "text-align": "center" }}
        ></div>
        <div
          id="educator-professional-test"
          style={{ width: "100%", "text-align": "center" }}
        ></div>
        <div
          id="professional-skill-test"
          style={{ width: "100%", "text-align": "center" }}
        ></div>

        {/* <Team data={this.state.landingPageData.Team} /> */}
        {/* <Contact data={this.state.landingPageData.Contact} /> */}
        <Con data={this.state.landingPageData.Contact} />
      </div>
    );
  }
}

export default App;
