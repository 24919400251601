import React, { Component } from "react";

export class features extends Component {
  render() {
    return (
      <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2 style={{color:"orange"}}>Features</h2>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.map((d,i) => (
                  <div  key={`${d.title}-${i}`} className="col-md-6 col-12 row text-center">
                    {" "}
                    {/* <i className={d.icon}></i> */}
                    {/* <div style={{paddingBottom:30}} className="col-xs-12 col-md-12"> <img src="img/image11.png" className="img-responsive" alt=""/> </div> */}
                    <img src={d.img} className="img-responsive center-block"  style={{width: "200px", height: "200px", paddingBottom:"30"}} alt=""/>

                    <h3 >{d.title}</h3>
                    <p style={{textAlign:"left",paddingLeft:20}}>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    );
  }
}

export default features;
