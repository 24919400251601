import React, { Component } from "react";
import  {storage}  from './firebase'
import firebase from './firebase'
import { Card ,Col ,Row} from 'antd';


const { Meta } = Card;

export class Gallery extends Component {
  constructor(props){
    super(props);
    this.state = {
      image:[],
  
    }
  
    }
  componentDidMount(){
    const a = firebase.database().ref('images');
    a.on('value',(snapshot) => {
      let images = snapshot.val();
      // console.log(images);
      
      let newState= [];
      for ( let image in images){
        newState.push({
          id: image,
          image:images[image],
       
        })
      }
      
      this.setState({
        image:newState
      })

    });


  }

  
  render() {
    // console.log(this.state.image);

    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2 style={{color:"orange"}}>Gallery</h2>
          </div>
          </div>
          <div className="row">

          { this.state.image.map( (d,i) => (
           <div  className="col-md-3 col-sm-6" key={i}>
            {/* <div className="thumbnail"> */}

          {/* <Card */}
         {/* hoverable */}
        {/* style={{ width: 240,boxShadow: '0px 0px 7px 0px rgba(0,0,0,0.75)',marginTop:30 }} */}
        <img alt="example" src={d.image} width="300" height="300" style={{marginTop:30}}/>
      {/* >   */}
      {/* </Card> */}
      </div>
      // </div>


  
  ))}
  </div>

          </div>


           
             
    );
  }
}

export default Gallery;
