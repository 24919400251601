import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Progress,
  Row,
  FormGroup,
  Input, Label,Table
} from 'reactstrap';
import { withRouter } from "react-router-dom";



export class Services extends Component {
  constructor(props){
    super(props);
   
  
    }
  fetchDetail = () => {
    let a = document.getElementById('features')
    a.scrollIntoView();
    
  
    // this.props.history.push({
    //   pathname:a
    // })
  }
    

  
  render() {
   
    return (
      <div id="services">
      <div className="text-center" >
        <div className="container">
          <div className="section-title">
            <h2 style={{color:"orange"}}>Our Services</h2>
            
          </div>
        </div>
        {/* <div className="text-center">
        <Table columns={columns} dataSource={data} pagination={false} />
        </div> */}
        <Row>

           <Col sm="12">
                <Card >
                  <CardBody>
                  <a href="#features"> 
                  <Table responsive  striped bordered onClick={this.fetchDetail} style={{textAlign:"center"}}>
      <thead >
        <tr >
          {/* <th>#</th> */}
          <th  style={{textAlign:"center"}}>Grade 6 and 7</th>
          <th style={{textAlign:"center"}}>Grade 8 and above</th>
          <th style={{textAlign:"center"}}>Working professionals</th>
          <th style={{textAlign:"center"}}>NGO’s/ Schools</th>

        </tr>
      </thead>
      <tbody>
        <tr>
          {/* <th scope="row">1</th> */}
          <td>Psychometric testing</td>
          <td>Psychometric testing</td>
          <td>Psychometric testing</td>
          <td>Psychometric testing</td>
        </tr>
        <tr>
          {/* <th scope="row">2</th> */}
          <td>One on one counseling</td>
          <td>One on one counseling</td>
          <td>One on one session</td>
          <td>One on one session with students</td>

        </tr>
        <tr>
          {/* <th scope="row">3</th> */}
          <td>Counseling with parents</td>
          <td>Session with parents</td>
          <td>Career planning</td>
          <td>Career exploration</td>

        </tr>
        <tr>
          <td>Career exploration</td>
          <td>Career exploration</td>
          <td></td>
          <td>Career planning</td>
        </tr>
        <tr>
          <td>Guiding students to make study plan</td>
          <td>Career planning/mapping (Guidance on stream/ subject selection)</td>
          <td></td>
          <td>One on one session with parents/guardian (if required)</td>

        </tr>
        <tr>
          <td></td>
          <td>Assistance in college admission (India/ abroad)and entrance exams</td>
          <td></td>
          <td></td>

        </tr>
        
        
      </tbody>
    </Table>
    </a>
                  </CardBody>
                </Card>
              </Col>
              </Row>

      </div>
      </div>
    );
  }
}

export default withRouter(Services);
