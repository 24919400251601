import React, { Component } from "react";

export class Navigation extends Component {
  constructor(...props) {
    super(...props)
    console.log(this.props) // this logged to console
    this.onNavigatorOptionClick = this.onNavigatorOptionClick.bind(this)

  }
 
  
onNavigatorOptionClick(val){
  let self = this
return function (e) {
  e.preventDefault()
  console.log('hhhhhh',self.props);
  self.props.onSelectNavigator(val)
} 
 }
  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll" href="#page-top" >
              <img className="navbar-brand page-scroll" src="img/pasted image 0.png" className="img-responsive" style={{ display: "flex", marginLeft: "auto", marginRight: "20", width: "35", float: "left" }} alt="" />
              <p style={{ color: "orange" }}> IKIGAI CAREER COUNSELING</p>
            </a>{" "}
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="#features" data-toggle="collapse" data-target=".navbar-collapse" className="page-scroll">
                  Features
                </a>
              </li>
              <li>
                <a href="#about" data-toggle="collapse" data-target=".navbar-collapse" className="page-scroll">
                  About
                </a>
              </li>
              <li>
                <a href="#services" data-toggle="collapse" data-target=".navbar-collapse" className="page-scroll">
                  Services
                </a>
              </li>
              <li class="dropdown">
                {/* <div class="dropdown"> */}
                <button class="btn btn-secondary dropdown-toggle" style={{ 'background-color': 'transparent', marginTop: '0.8rem' }} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                 PYSCHOMETRIC TEST
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  {/* <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a> */}
                  <a href="#commerce-test" data-toggle="collapse" data-target=".navbar-collapse" className="page-scroll">
                    Commerce test
                  </a>
                  <div> <a href="#engineering-test" data-toggle="collapse" data-target=".navbar-collapse" className="page-scroll">
                    Engineering test
                  </a></div>
                  <div> <a href="#ideal-test" data-toggle="collapse" data-target=".navbar-collapse" className="page-scroll">
                    Ideal test
                  </a></div>
                  <div> <a href="#humanities-test" data-toggle="collapse" data-target=".navbar-collapse" className="page-scroll">
                  Humanities test
                  </a></div>
                  <div> <a href="#stream-selector-test" data-toggle="collapse" data-target=".navbar-collapse" className="page-scroll">
                  Stream Selector-test
                  </a></div>
                  <div> <a href="#skill-based-test" data-toggle="collapse" data-target=".navbar-collapse" className="page-scroll">
                  Skill Based test
                  </a></div>
                  <div> <a href="#educator-professional-test" data-toggle="collapse" data-target=".navbar-collapse" className="page-scroll">
                  Educator test
                  </a></div>
                  <div> <a href="#professional-skill-test" data-toggle="collapse" data-target=".navbar-collapse" className="page-scroll">
                  Professional Skill test
                  </a></div>
                </div>
                {/* </div> */}
              </li>
              <li>
                <a href="#portfolio" data-toggle="collapse" data-target=".navbar-collapse" className="page-scroll">
                  Gallery
                </a>
              </li>
              <li>
                <a href="#testimonials" data-toggle="collapse" data-target=".navbar-collapse" className="page-scroll">
                  Testimonials
                </a>
              </li>
              {/* <li>
                <a href="#team" className="page-scroll">
                  Team
                </a>
              </li> */}
              <li>
                <a href="#contact" data-toggle="collapse" data-target=".navbar-collapse" className="page-scroll">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav >
    );
  }
}

export default Navigation;
