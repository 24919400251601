import React, { Component } from "react";
import firebase from './firebase'


export class testimonials extends Component {
  constructor(props){
  super(props);
  this.state = {
    name:[],

  }

  }
  componentDidMount(){
    const a = firebase.database().ref('contacts');
    a.on('value',(snapshot) => {
      let names = snapshot.val();
      // console.log(names);
      
      let newState= [];
      for ( let name in names){
        newState.push({
          id: name,
          fullName:names[name].fullName,
          message:names[name].message

        })
      }
      
      this.setState({
        name:newState
      })

    });


  }
  render() {
    // console.log(this.state.name);
    
   
    return (
      <div id="testimonials">
        <div className="container">
          <div className="section-title text-center">
            <h2 style={{color:"orange"}}>What our clients say</h2>
          </div>
          <div className="row">
            {this.state.name
              ? this.state.name.map((d, i) => (
                  // <div key={`${d.name}-${i}`} className="col-md-4">
                  <div key={`${d.fullName}-${i}`} className="col-md-4">
                    <div className="testimonial">
                      <div className="testimonial-image">
                        {" "}
                        {/* <img src={d.img} alt="" />{" "} */}
                        <i className="fa fa-user"></i>
                      </div>
                      <div className="testimonial-content">
                        <p>"{d.message}"</p>
                        <div className="testimonial-meta"> - {d.fullName} </div>
                      </div>
                    </div>
                 </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default testimonials;
