import React, { Component } from 'react'

export class about extends Component {
  render() {
    return (
        <div id="about" >
        <div className="container">
        <h2 style={{color:"orange"}} className="text-center">About Us</h2>

          <div className="row">
          {/* <div className="col-xs-12 col-md-6"> <img src="img/about.jpg" className="img-responsive" alt=""/> </div> */}
          <div className="center" style={{ display: "block",marginLeft: "auto",marginRight: "auto",width: "350"}} > <img src="img/ikigai imp.jpg" className="img-responsive" style={{width: "90%"}}  alt=""/> </div>

                {/* <div className="col-xs-12 col-md-6"> <img src="img/about.jpg" className="img-responsive" alt=""/> </div> */}

            <div className="col-xs-12 col-md-12">
              <div className="about-text">
            
                <p></p>
                <p style={{fontSize:20}}>{this.props.data ? this.props.data.paragraph : 'loading...'}</p>
                <p style={{fontSize:20}} >{this.props.data ? this.props.data.paragraph1 : 'loading...'}</p>
                <p style={{fontSize:20}}>{this.props.data ? this.props.data.paragraph2 : 'loading...'}</p>

 
                <h3>Logo meaning</h3>
                <div className="list-style">
                <p style={{fontSize:20}}> Explanation: Ikigai 
                The word consists of 'Iki' (to live) and 'gai' (reason) - i.e “To live with a reason”
                Ikigai is a Japanese concept that means "a reason for being". 
                The word refers to having a direction or purpose in life, which makes one's life worthwhile, and to reach one's goal an individual takes spontaneous and willing actions giving them satisfaction and a sense of meaning to life.
                </p>
            <p style={{fontSize:20}}>Having said that, the above logo consists of 4 petals that stands for:
            </p>

                  <div className="col-lg-12 col-sm-12 col-xs-12">
                    <ul style={{fontSize:30}}>
                      {this.props.data ? this.props.data.Why.map((d, i) =><strong> <p style={{fontSize:"20"}}  key={`${d}-${i}`}>{d}</p> </strong>) : 'loading'}
                    </ul>
                    <p style={{fontSize:20}}>When these 4 things meet it makes a person successful and satisfied in his/her career.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default about
