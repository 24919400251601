
import * as firebase from "firebase"
import "firebase/storage"


// var firebaseConfig = {
//     apiKey: "AIzaSyD0J24yGn1EJukRuK3OcDooEvZiMlZl5l4",
//     authDomain: "react-crud-24465.firebaseapp.com",
//     databaseURL: "https://react-crud-24465.firebaseio.com",
//     projectId: "react-crud-24465",
//     storageBucket: "react-crud-24465.appspot.com",
//     messagingSenderId: "156575039243",
//     appId: "1:156575039243:web:46f6304098a18a3327ec0e"
//   };
var firebaseConfig = {
  apiKey: "AIzaSyAyUknb8udnU9Jp-R7LrZy2_u2P1nGtK-4",
  authDomain: "carrer-counseling.firebaseapp.com",
  databaseURL: "https://carrer-counseling.firebaseio.com",
  projectId: "carrer-counseling",
  storageBucket: "carrer-counseling.appspot.com",
  messagingSenderId: "589604478551",
  appId: "1:589604478551:web:000a63bad19a3dafea0d12"
};
  // Initialize Firebase
  var fireDb = firebase.initializeApp(firebaseConfig);
  const storage = firebase.storage()
  export { storage ,firebase as default}