import React from 'react';
import axios from 'axios';
import { Row, Col } from 'antd';


class Con extends React.Component{
  
  constructor(props) {
	super(props);
	this.state = {
  	name: '',
  	email: '',
    message: '',
    age:''
	}
  }

  handleSubmit(e){
    e.preventDefault();
    axios({
      method: "POST", 
      // url:"http://localhost:3002/send", 
      url:"https://nodemailerwebapp.herokuapp.com/",
      headers: {
        'Content-Type': 'application/json',
      },
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success'){
        alert("Message Sent."); 
        this.resetForm()
      }else if(response.data.status === 'fail'){
        alert("Message failed to send.")
      }
    })
  }

  resetForm(){
    
     this.setState({name: '', email: '', message: '',age:''})
  }
  
  render() {

return (<div>
    <div id="contact">
      <div className="container">
        <div className="col-md-8">
          <div className="row">
            <div className="section-title">
              <h2>Get In Touch</h2>
              <p>
                Please fill out the form below to send us an email and we
                will get back to you as soon as possible.
              </p>
            </div>
               <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
  	<div className="form-group">
      	<label htmlFor="name">Name</label>
      	<input type="text" className="form-control" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} required/>
  	</div>
  	<div className="form-group">
      	<label htmlFor="exampleInputEmail1"> Your Email address</label>
      	<input type="email" className="form-control" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} required/>
  	</div>
  	<div className="form-group">
      	<label htmlFor="message">Message</label>
      	<textarea className="form-control" rows="5" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} required /> 
  	</div>
      <div className="form-group">
                  <label ><strong> Age group</strong></label>
                  <select className="form-control"  name="Age"  id="age" value={this.state.age} placeholder="Age group"  onChange={this.onAgeChange.bind(this)} required >
                                    <option value="">Select option</option>
                                    <option value="Grade 6 and 7"> Grade 6 and 7</option>
                                    <option value="Grade 8 and above"> Grade 8 and above</option>
                                    <option value="Working professionals">Working professionals</option>
                                    <option value="Schools and NGOs">Schools and NGOs</option>

                                </select>
                    <p className="help-block text-danger"></p>
                  </div>
  	<button type="submit" className="btn btn-primary">Submit</button>
  	</form>
            
          </div>
        </div>
        <div className="col-md-3 col-md-offset-1 contact-info">
         
          <div className="contact-item">
          <h3>Contact Info</h3>

            <p>
              <span>
                <i className="fa fa-phone"></i> Phone Number
              </span>{" "}
              {this.props.data ? this.props.data.phone : "loading"}
            </p>
          </div>
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-envelope-o"></i> Email
              </span>{" "}
              {this.props.data ? this.props.data.email : "loading"}
            </p>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="social">
              <ul>
                <li>
                  <a
                    href={this.props.data ? this.props.data.facebook : "/"} target="_blank"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href={this.props.data ? this.props.data.instagram : "/"} target="_blank">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </div>)
  }

  onNameChange(event) {
	this.setState({name: event.target.value})
  }

  onEmailChange(event) {
	this.setState({email: event.target.value})
  }

  onMessageChange(event) {
	this.setState({message: event.target.value})
  }

  onAgeChange(event) {
	this.setState({age: event.target.value})
  }
}

export default Con;
